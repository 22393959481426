<template>
    <autocomplete ref="ac" :items="items" :text="text" :isAsync="isLoading" @change="debounce" @selected="onSelected" :placeholder="placeholder" :icon="icon" @clear="onClear"></autocomplete>
</template>
<script>
import Autocomplete from '@/components/Autocomplete'
export default {
    name:'AsyncAutocomplete',
    components: {
        Autocomplete
    },
    props:{
      url:{
        type:String,
        required:false,
        default:null
      },
      text:{
        type:String,
        required:false,
        default:'nombre'
      },
      min:{
        type:Number,
        required:false,
        default:4
      },
      placeholder:{
        type:String,
        required:false,
        default:'Teclea texto a buscar'
      },
      icon: {
        type:Object,
        required:false
      }
    },
    data:()=>({
        isLoading:false,
        items:[]
    }),

    methods:{
        onSelected:function(val){
            this.$emit('selected',val);
        },
        onClear:function(){
            this.$emit('clear');
        },
        // Simple forma de resolverlo
        // https://stackoverflow.com/a/56822735
        debounce:function(value){
            if(value.trim().length>=this.min) {
                if( this.timerId>-1){
                    clearTimeout(this.timerId);
                }
                this.timerId = setTimeout(() => {
                    this.fnSearch(value.trim())
                }, 500)
            }
        },
        fnSearch(value){
            if(value==null || value.trim().length<this.min){
                return;
            }
            if( this.isLoading){
                this.debounce(value); // Esperar otros milisegundos
                return;
            }
            this.isLoading = true;
            this.$http.get(this.url+value.trim()).then((data) => {
                if(data){
                    this.items=data;
                }
                else{
                    this.items = []
                }
            }).then(function(){
                    this.isLoading=false;
                }.bind(this)
            );
        },
        focus:function(){
            this.$refs.ac.focus();
        },
        reset:function(){
            this.$refs.ac.clean();
            this.items=[]
        }
    }
}
</script>