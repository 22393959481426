export class HorarioUtils {

  constructor() {
      this.i=0;
  }

  isAvailable(hr,hrs){
      if( this.i<hrs.length && hr>=hrs[this.i][0] ){
          if( hr>=hrs[this.i][1]){
              this.i++;
              return this.isAvailable(hr,hrs);
          }
          return true;
      }
      return false;
  }

  statusSlot(p,hr,hrs){
      if(hrs!=null&&this.i<hrs.length)
          if(hr[0]>=hrs[this.i][0]) {
              //console.log("  ["+ hr +"] >= ["+hrs[this.i][0] +' , '+hrs[this.i][1] + "]")
              // La hora que se busca en los horarios seleccionados podría
              // estar ocupada.
              // Si la hora de terminación está dentro del intervalo
              if(hr[0]>=hrs[this.i][1]){
                  this.i++;
                  return this.statusSlot(p,hr,hrs);
              }
              //console.log("---Found "+p+" =? " + hrs[this.i][2]);
              // Este espacio está ocupado. Ahora decidir por quién.
              return p==hrs[this.i][2]?1:-1;
          } else {
              // Ahora buscar si la hora de terminación está dentro del intervalo
              if(hr[1]>hrs[this.i][0]){
                  // Ocupado
                  return p==hrs[this.i][2]?1:-1;
              }
          }
      return 0;
  }


  reset(){
      this.i=0;
  }
}


export class BinaryTree {
  constructor() {
    this.root = null
  }

  // Insert a value as a node in the BinaryTree
  add(value,item) {
    let newNode = new Node(value,item)

    // If root empty, set new node as the root
    if (!this.root) {
      this.root = newNode
    } else {
      this.insertNode(this.root, newNode)
    }
  }

  // helper function
  insertNode(root, newNode) {
      if(newNode.value===root.value) {
        throw new Error('two items of the same value added '+root.value);
      }
      if (newNode.value < root.value) {
          // If no left child, then just insesrt to the left
          if (!root.left) {
          root.left = newNode
          } else {
          this.insertNode(root.left, newNode)
          }
      } else {
          // If no right child, then just insesrt to the right
          if (!root.right) {
          root.right = newNode
          } else {
          this.insertNode(root.right, newNode)
          }
      }
  }

  // Remove a node with the value passed
  remove(value) {
    if (!this.root) {
      return 'Tree is empty!'
    } else {
        this.root= this.removeNode(this.root, value)
        return this.root;

    }
  }

  // helper function
  removeNode(root, value) {
    if (!root) {
      return null
    }

    // If value is less than root value, go to the left subtree
    if (value < root.value) {
      root.left = this.removeNode(root.left, value)
      return root
    // If value is greater than root value, go to the right subtree
    } else if (value > root.value) {
      root.right = this.removeNode(root.right, value)
      return root
    // If we found the value, remove the node
    } else {
      // If no child nodes, just remove the node
      if (!root.left && !root.right) {
        root = null
        return root
      }

      // If one child (left)
      if (root.left) {
        root = root.left
        return root
      // If one child (right)
      } else if (root.right) {
        root = root.right
        return root
      }

      // If two child nodes (both)
      // Get the minimum of the right subtree to ensure we have valid replacement
      let minRight = this.findMinNode(root.right)
      root.value = minRight.value

      // Make sure we remove the node that we replaced the deleted node
      root.right = this.removeNode(root.right, minRight.value)
      return root
    }
  }

  ascOrder = function(){
      this.items=[]
      this.inOrder(this.root);
      return this.items;
  }

  inOrder=function(root) {
      if (root !== null) {
         this.inOrder(root.left);
         this.items.push(root.item);
         this.inOrder(root.right);
      }
  }

  findMinNode(root) {
    if (!root.left) {
      return root
    } else {
      return this.findMinNode(root.left)
    }
  }

  // Return boolean value depending on the existence of the value in the tree
  search(value) {
    if (!this.root) {
      return 'Tree is empty'
    } else {
      return Boolean(this.searchNode(this.root, value))
    }
  }

  searchNode(root, value) {
    if (!root) {
      return null
    }

    if (value < root.value) {
      return this.searchNode(root.left, value)
    } else if (value > root.value) {
      return this.searchNode(root.right, value)
    }

    return root
  }
}

class Node {
  constructor(value,item) {
    this.left = null
    this.right = null
    this.value = value
    this.item = item
  }
}