import axios from "axios";
import { Urls } from "@/consts.js";

const ConsultorioService = {
    obtPaises: function () {
        return axios.get(Urls.CONSULTORIO_URL + "/paises/");
    },
    obtHorarios:function(){
        return axios.get(Urls.CONSULTORIO_URL+"/obtHorarios/");
    },
    obtDireccion:function(id){
        return axios.get(Urls.CONSULTORIO_URL+"/direccion/"+id);
    },
    obtColonias:function(cp){
        return axios.get(Urls.CONSULTORIO_URL+"/colonias/"+cp);
    },
    execAction:function(action, params){
        return axios.post(Urls.CONSULTORIO_URL+"/"+action, params);
    },
    eliminar:function(id){
        return axios.delete(Urls.CONSULTORIO_URL+"/delete/"+id);

    }


};

export default ConsultorioService;