<template>
  <div class="mt-5">
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="3" xl="3">
        <v-row>
          <v-subheader>
            <span class="title ml-2 mr-2">Mis consultorios</span>
            <v-btn
              dark
              relative
              float
              right
              small
              fab
              color="success"
              @click="nuevo"
              title="Nuevo consultorio"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-subheader>
        </v-row>
        <v-row>
          <v-col
            id="scroll-target0"
            :style="
              isMobile
                ? 'max-height: 200px; background: whitesmoke;'
                : 'max-height: 500px'
            "
            class="overflow-y-auto"
            cols="12"
          >
            <v-list v-scroll:#scroll-target0="onScroll0" class="pt-0">
              <v-list-item-group
                v-model="consIndex"
                color="primary"
                :mandatory="mandatory"
                @change="!delDiag?
                                           onPlaceSelected(consIndex):null"

              >

                <v-list-item
                  class="pb-1 pt-1 mb-1"
                  v-for="(item, index) in cons"
                  :key="'place_' + item.lugar_id"
                  dense
                  @click="!delDiag?
                  onPlaceSelected(index):null"
                >
                  <v-list-item-content>
                    {{ item.lugar }}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="delDiag = true"
                      title="Eliminar consultorio"
                    >
                      <v-icon color="red lighten-1"
                        >mdi-trash-can-outline</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="5" xl="5">
        <!--
        <div class="d-inline-block tercio pl-4 mr-2"> -->
        <v-form ref="form" v-model="valid" :lazy-validation="false">
          <v-text-field
            v-model="lugar"
            v-model.trim="lugar"
            v-model.lazy="lugar"
            :counter="60"
            :rules="textRule(60)"
            label="Nombre del consultorio"
            ref="lugar"
            required
          ></v-text-field>
          <v-text-field
            v-model="telefono"
            v-model.trim="telefono"
            v-model.lazy="telefono"
            :counter="counterTelefono"
            :rules="telefonoRule"
            :maxlength="counterTelefono"
            label="Teléfono fijo"
            required
          ></v-text-field>
          <v-text-field
            v-model="dir"
            :rules="textRule(128)"
            :counter="128"
            :label="labelAddr"
            ref="direccion"
            required
          ></v-text-field>

          <v-select
            v-model="pais_tz"
            v-show="!isMx() && paises && paises.length > 0"
            :items="paises"
            item-text="name"
            label="Zona horaria"
            @change="onPaisChange"
            outlined
          ></v-select>
          <template v-if="isMx()">
            <label for="">Busca código postal</label>
            <async-autocomplete
              ref="aac"
              url="/api/consultorios/colonias/"
              @selected="onZCSelected"
              :min="4"
              placeholder="Código postal"
            ></async-autocomplete>
            <v-chip
              v-if="colonia && colonia.cp"
              class="primary--text v-chip--active"
              close
              @click:close="quitarColonia"
            >
              <strong class="mr-1">{{ colonia.cp + "," }}</strong>
              {{ colonia.nombre + ", " + colonia.municipio +", "+colonia.estado }}
            </v-chip>
          </template>

          <v-text-field label="Costo de la consulta"
            prefix="$"
            required
            :rules="costoRule"
            v-model.number="costo"
            v-model="costo"
            counter="7"
            maxlength="7"
            >

          </v-text-field>


          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="text-left mt-4">
                Intervalo de atención:
              </div>
            </template>
            <span>Duración aproximada de la consulta en minutos.</span>
          </v-tooltip>
          <v-chip-group
            v-model="inter"
            active-class="primary--text"
            @change="onIntervalChange"
          >
            <v-chip class="ma-2" value="60" @click="onIntervalClicked(60)"
              >60 min</v-chip
            >
            <v-chip class="ma-2" value="30" @click="onIntervalClicked(30)"
              >30 min</v-chip
            >
            <v-chip class="ma-2" value="20" @click="onIntervalClicked(20)"
              >20 min</v-chip
            >
            <v-chip class="ma-2" value="15" @click="onIntervalClicked(15)"
              >15 min</v-chip
            >
          </v-chip-group>
          <div class="text-right">
            <v-btn class="success" :loading="guardando" @click="guardar"
              >Guardar</v-btn
            >
          </div>
          <!--
          <p class="caption mb-n2">{{ colonia }}</p>
          <span class="caption">
            --{{ inter }}/{{ intervals }}--<br />
            --Consultorio: {{ consIndex }}--<br />
            --Día: {{ dayIndex }}--<br
          /></span>
          -->
        </v-form>
        <!--</div>-->
      </v-col>
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        style="background: whitesmoke;"
        class=""
      >
        <v-row style="">
          <v-subheader
            title="Selecciona los horarios en los atiendes según el día"
            >Días y horarios de atención</v-subheader
          >
        </v-row>
        <v-row>
          <v-col
            xs="2"
            sm="2"
            md="3"
            lg="3"
            xl="2"
            style="/*background: whitesmoke;*/"
          >
            <div
              class="d-inline-block-1 text-left"
              style="/*max-width:100px;*/"
            >
              <v-list-item-group
                v-model="dayIndex"
                style="/*background: pink;*/"
                mandatory
                color="primary"
                @change="onDayChange"
              >
                <v-list-item
                  class="pb-1 pt-1 mb-1 body-2"
                  v-for="(item, i) in dias"
                  :key="'dow_' + i"
                  dense
                  title="Selecciona para indicar los horarios en los que atiendes este día"
                >
                  <v-list-item-content>
                    {{ item }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-col>
          <v-col
            xs="10"
            sm="10"
            md="9"
            lg="9"
            xl="10"
            style="/*background:lightBlue;*/"
          >
            <div
              id="scroll-target"
              class="ml-1 overflow-y-auto text-left d-block "
              style="/*background: whitesmoke;*/"
            >
              <div
                v-scroll:#scroll-target="onScroll"
                align="center"
                justify="center"
                style="height: 450px"
              >
                <v-chip-group
                  v-model="selectedHrs"
                  active-class="primary--text"
                  column
                  multiple
                >
                  <v-chip
                    filter
                    class="ma-2"
                    v-for="(item, i) in hrs"
                    :ref="'hr_a_' + i"
                    v-bind:key="'hr_a_' + i"
                    :disabled="item.status === -1"
                    :input-value="item.value"
                    @click="chipSelected(item, 'hr_a_' + i)"
                  >
                    {{ item.hr }}
                  </v-chip>
                </v-chip-group>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-12"> </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="delDiag"
      :persistent="borrando"
      @click:outside="delDiag = borrando ? true : false"
      max-width="295"
    >
      <v-card>
        <v-card-title class="headline-no blue darken-2 white--text"
          >Eliminar consultorio</v-card-title
        >

        <v-card-subtitle class="mt-3 subtitle-1">
          Esta operación no podrá revertirse,
          <strong>¿Deseas continuar?</strong>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            text
            v-show="!borrando"
            @click="delDiag = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="borrando"
            @click="borrar"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
iframe {
  height: 85vh;
  width: 95vw;
  border: 0;
  margin-top: 8px;
}
</style>
<script>
import AsyncAutocomplete from "@/components/AsyncAutocomplete";
import { HorarioUtils, BinaryTree } from "@/consultorioUtils.js";
import ConsultorioService from "@/services/consultorio.service.js";

let helper = new HorarioUtils();
export default {
  name: "Consultorios",
  components: {
    "async-autocomplete": AsyncAutocomplete,
  },
  data: () => ({
    //usableHeight: 480,
    editForm: false,
    valid: false,
    sb: {
      show: false,
      msg: "Administración de consultorios",
      color: "info",
      to: 3000,
    },
    consIndex: -1,
    mandatory: true,
    intervalClicked: false,
    dayIndex: 0,
    dias: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],

    lugar: "",
    dir: "",
    inter: 30,
    telefono: "",
    colonias: [],
    colonia: {},
    paises: [],
    country_tz: "",
    cons: [],
    hrsxdow: [],
    hrs: [],
    selectedHrs: [],
    isMobile: false,
    offsetTop: 0,
    offsetTop0: 0,
    tmpTree: [],
    guardando: false,
    delDiag: false,
    borrando: false,
    pais_tz: undefined,
    costo:"0"

  }),

  computed: {
    userInfo: function() {
      return this.$store.state.usuario;
    },
    chipValue: function() {
      return this.inter ? this.inter : 30;
    },
    intervals: function() {
      return (60 / this.inter) * 24;
    },
    counterTelefono: function() {
      return this.$store.state.usuario ? (this.isMx() ? 10 : 15) : 10;
    },
    telefonoRule: function() {
      const mx = this.isMx();
      const re = mx ? /^[1-9][0-9]{9}$/ : /^([1-9]{1})+([0-9]{4,15})$/;
      const mensaje = mx
        ? "Solo se aceptan 10 dígitos"
        : "Solo se aceptan dígitos";
      const rules = [];
      const required = (value) => !!value || "Campo obligatorio.";
      const rule = (value) => re.test(value) || mensaje;
      rules.push(required);
      rules.push(rule);
      return rules;
    },
    labelAddr: function() {
      return this.isMx() ? "Calle y número" : "Dirección";
    },
    costoRule:function(){
        const rules =[];
        const pattern = /^[0-9]{1,7}$/;
        const required = (value)=> !!value || "Campo obligatorio.";
        const rule = (value)=>pattern.test(value) || "Máximo 7 dígitos";
        rules.push(required);
        rules.push(rule);
        return rules;
    }
  },

  methods: {
    textRule: function(maximo) {
      const rules = [];
      rules.push((v) => !!v || "Este campo es obligatorio");
      rules.push(
        (v) =>
          (v && v.length > 3 && v.length < maximo) ||
          `Mínimo 3 caracteres y máximo  ${maximo}`
      );
      return rules;
    },
    nuevo: function() {
      console.log('nuevo init: '+this.costo);

    this.$nextTick(() => {
      if (this.$refs.form) this.$refs.form.reset();
      if (this.$refs.aac) this.$refs.aac.reset();

      this.inter = 30;
      this.costo = 0;
      this.colonia = undefined;
      this.mandatory = false;
      this.consIndex = -1;

      this.inter = "30";
      this.costo = "0"
      console.log("MX==>" + this.isMx());
      this.pais_tz = this.paises[0].name;
      this.tmpTree = this.nuevoArbol();
      this.getHrs(this.tmpTree[this.dayIndex]); // Recalcular horarios disponibles
      this.$refs.lugar.focus();
      });
      console.log('nuevo fin: '+this.costo);
    },
    chipSelected: function(item, ref) {
      //console.log(this.$refs[ref]);
      //f=> significa que el chip tiene la clase active
      let treeArr =
        this.consIndex < 0
          ? this.tmpTree[this.dayIndex]
          : this.hrsxdow[this.dayIndex];
      let id = this.consIndex != -1 ? this.cons[this.consIndex].lugar_id : -1;
      if (!this.$refs[ref][0].isActive) {
        //add
        treeArr.add(item.se[0], [item.se[0], item.se[1], id]);
      } else {
        //remove
        treeArr.remove(item.se[0]);
        //let resultado = treeArr.remove(item.se[0]);
        //console.dir(treeArr.ascOrder());
        //console.log("\n\n");
      }

      if (this.consIndex < 0) this.tmpTree[this.dayIndex] = treeArr;
      else this.hrsxdow[this.dayIndex] = treeArr;
    },
    getDefaultHrs: function() {
      if (this.consIndex < 0) {
        this.getHrs(this.tmpTree[this.dayIndex]);
      } else {
        this.getHrs(this.hrsxdow[this.dayIndex]);
      }
    },
    getHrs: function(arbol) {
      /*console.log(
        " > consIndex: " +
          this.consIndex +
          ", dayIndex: " +
          this.dayIndex +
          ", inter: " +
          this.dayIndex
      );*/
      helper.reset(); // Importante para reiniciar el índice
      var selectedHrs = [];

      let items = [];

      let factor = 60 / this.inter;

      let startH;
      let min;
      let endM;
      let startM;
      var status;
      var hr;
      let id = this.consIndex != -1 ? this.cons[this.consIndex].lugar_id : -1; // significaría nuevo

      for (let i = 0; i < this.intervals; i++) {
        startH = String(Math.floor(i / factor)).padStart(2, "0");
        min = (i % factor) * this.inter;
        endM = min + parseInt(this.inter) - 1;
        startM = String(min).padStart(2, "0");
        hr = startH + ":" + startM + " - " + startH + ":" + endM;

        let startEndArr = [
          Math.floor(i / factor) + min / 100,
          Math.floor(i / factor) + endM / 100,
        ];
        status = helper.statusSlot(
          id,
          startEndArr,
          arbol.ascOrder()
          //this.hrsxdow[this.dayIndex].ascOrder()
        );

        if (status == 1) selectedHrs.push(i);
        items.push({ hr, status, value: status === 1, se: startEndArr }); // se: start-end
      }

      this.hrs = items; // Todas las horas del día
      this.selectedHrs = selectedHrs; // Horarios resaltados
      // 0 00:00-00:15,
      // 1 00:15-00:30
      // 2 00:30-00:45
      // 3 00:45-01:00
      // 4 01:00-01:15
      // 5 01:15-01:30
    },
    onPlaceSelected: function(index) {
      //console.log("- onPlaceSelected -",index);
      this.consIndex = index;

      console.log("- onPlaceSelected2 -"+this.consIndex);
      this.$refs.form.reset();
      if(this.consIndex === undefined || this.consIndex < 0){
        console.log("this.consIndex");
        this.nuevo();
      }else
      {
        this.$nextTick(() => {
          this.mandatory = true;
          this.lugar = this.cons[this.consIndex].lugar;
          this.inter = this.cons[this.consIndex].inter;
          this.telefono = this.cons[this.consIndex].telefono;
          this.costo = this.cons[this.consIndex].costo;
          this.dir = this.cons[this.consIndex].direccion;
          this.pais_tz = this.cons[this.consIndex].country_tz;
          this.validate();
          this.getDefaultHrs();
          this.getAddr();
        });
      }

    },
    onDayChange: function() {
      //console.log("onDayChange: " + this.dayIndex);
      this.getDefaultHrs();
    },
    onIntervalChange: function() {
      if (this.intervalClicked) {
        this.intervalClicked = false;
        this.getDefaultHrs();
      }
    },
    onIntervalClicked: function(inter) {
      this.intervalClicked = inter != this.inter;
      if (inter != this.inter) {
        //console.log("Interval clicked " + inter + " =? " + this.inter);
      }
    },
    getAddr: function() {
        let idx = this.consIndex;

        console.log('getAddr: '+idx);
        if(idx === undefined ||  idx < 0)
            return;

        ConsultorioService.obtDireccion(this.cons[idx].lugar_id).then((data)=>{
            console.log('obtDireccion--->'+data.lugarId);
            if (data && idx == this.consIndex && data.lugarId == this.cons[idx].lugar_id) {
              this.dir = data.addr;
              this.country_tz = data.country + " - " + data.tz;
              this.colonia = data;
            }
            else{
                this.colonia = undefined;
            }
        });
    },
    message: function(msg, color) {
      this.$root.showMessage(msg, color);
    },
    error: function(err) {
      this.$root.showMessage(err, "error");
    },
    onZCSelected: function(obj) {
      console.log('onZcSelected: '+JSON.stringify(obj));
      this.colonia = obj;


      this.country_tz = "México - " + obj.tz;
    },
    obtPaises: function() {

        ConsultorioService.obtPaises().then((data)=>{
            if (data) {
                this.paises = data;
            }
            console.log("obtPaises: "+this.paises.length);
        });
    },
    obtHorarios: function() {
        console.log('Obt nuevos horarios');
        ConsultorioService.obtHorarios().then((data)=>{

            this.horariosObtenidos(data);
        }).then(function(){
            this.$nextTick(() => {
            //this.nuevo();
            });
        }.bind(this));
    }, // obtHorarios
    horariosObtenidos: function(data) {
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.reset();
        if (this.$refs.aac) this.$refs.aac.reset();
        this.consIndex = -1;
        this.cons = [];
        this.colonia = undefined;
       this.costo = 0;
      });
      let start = 0;
      let end = 0;
      let hrsxdow = [];
      // ### Aquí se convierten los horarios a float y de paso se ordenan en un árbol binario
      if (data && data.length > 0)
        data.forEach((p) => {
          // Recorrer cada consultorio
          let dow = [];
          let inter = parseInt(p.inter);

          for (let i = 0; i < 7; i++) {
            // 7 días de la semana
            // Definir el árbol cuando no exista (sólo la primera vez x día)
            if (!hrsxdow[i]) hrsxdow[i] = new BinaryTree();
            let arr = p[String(i)]; // Los horarios están almacenados por día [0], [1], etc
            let narr = [];
            if (arr) {
              arr.forEach((hr) => {
                //
                // Recorrer cada horario de "este" día

                let interval = hr.replace(/:/g, ".").split("-"); // Inicio y fin 9:30=9.30
                start = parseFloat(interval[0]);
                end = parseFloat(interval[1]);
                //
                var n = Math.ceil((start - Math.floor(start)) * 100);
                var nx;
                for (let x = start; x < end; ) {
                  n = (n >= 60 ? 0 : n) + inter;
                  nx = n >= 60 ? Math.floor(x) + 1 : x + n / 100;

                  narr.push([x, nx]);
                  hrsxdow[i].add(x, [x, nx, p.lugar_id]);
                  x = nx;
                }

                //narr.push([start, end]);

                // Para búsqueda rápida de horarios disponibles.
                // Los horarios se ponen en un árbol por día de la semana (no por
                // consultorio)
                //hrsxdow[i].add(start, [start, end, p.lugar_id]);
              });
            }
            dow[i] = narr;
          }
          p.hrs = dow;
        });

      this.$nextTick(() => {
        this.hrsxdow = hrsxdow;
        this.cons = data;
        //this.consIndex = -1;
        if (this.cons.length == 0) {
          this.message("Es necesario que registres tu consultorio.", "success");
          this.$nextTick(() => {
            this.nuevo();
          });
        }
      });
    },
    nuevoArbol: function() {
      var tmpArbol = [];
      if (!this.hrsxdow || this.hrsxdow.length == 0)
        for (var i = 0; i < 7; i++) tmpArbol.push(new BinaryTree());

      var j = 0;
      this.hrsxdow.forEach((p) => {
        let items = p.ascOrder();
        let tree = new BinaryTree();
        items.forEach((element) => {
          tree.add(element[0], element);
        });
        tmpArbol[j++] = tree;
      });

      return tmpArbol;
    },
    isMx: function() {
      return this.$store.state.usuario.isoPais == 'MX';
    },
    quitarColonia: function() {
      this.colonia = undefined;
      this.$refs.aac.focus();
      this.$refs.aac.reset();
    },
    onScroll: function(e) {
      this.offsetTop = e.target.scrollTop;
    },
    onScroll0: function(e) {
      this.offsetTop0 = e.target.scrollTop;
    },
    onResize: function() {
      this.isMobile = window.innerWidth < 1264;
    },
    guardar: function() {
      //console.log(this.country_tz);
      this.validate();

      if (!this.valid) {
        this.message(
          "El nombre del consultorio, teléfono, dirección, horarios, costo son requeridos",
          "warning"
        );
      } else if (this.isMx() && (!this.colonia || this.colonia == null || this.colonia == undefined)) {
        this.message("Busca y selecciona una colonia", "warning");
      } else {
        let semana = this.getHorariosDeAtencion();
        if (semana == null) return;

        var data = new FormData();
        for (let i = 0; i < 7; i++) data.append("d" + i, semana["d" + i]);

        data.append("name", this.lugar);
        data.append("fijo", this.telefono);
        data.append("addr", this.dir);
        data.append("interval", this.inter);
        data.append("costo", this.costo);
        var arr;
        if (this.isMx()) {
          arr = this.country_tz.split(" - ");
          data.append("country", arr[0]);
          data.append("tz", arr[1]);
          data.append("zipCode", this.colonia.cp);
          data.append("county", this.colonia.nombre);
          data.append("city", this.colonia.municipio);
          data.append("state", this.colonia.estado);
          data.append("tz", this.colonia.tz);
          //data.append("country", this.colonia.country);
        } else {
          arr = this.pais_tz.split(" - ");
          data.append("country", arr[0]);
          data.append("tz", arr[1]);
          data.append("zipCode", "1");
          data.append("county", "c");
          data.append("city", "c");
          data.append("state", "s");
        }

        var action = "create";
        if (this.consIndex > -1) {
          action = "update";
          data.append("li", this.cons[this.consIndex].lugar_id);
        }

        this.guardando = true;
        ConsultorioService.execAction(action,data).then((data)=>{
            var reload = false;
            if (action == "update") {
                var ok = data > 0;
                this.message(
                  ok
                    ? "Los datos del consultorio han sido actualizados"
                    : "El consultorio no pudo ser actualizado.",
                  ok ? "success" : "error"
                );
                reload = ok;
              } else if (data && data != null) {
                this.horariosObtenidos(data);
                this.message("El consultorio ha sido registrado", "success");
              } else {
                this.message(
                  "No fue posible guardar el consultorio, intenta más tarde.",
                  "error"
                );
                reload = true;
              }
              console.log(reload)
              if (reload) this.obtHorarios();
        }).then(function() {
                this.guardando = false;

        }.bind(this));







      }
    },
    getHorariosDeAtencion() {
      let arbolPrincipal = this.consIndex < 0 ? this.tmpTree : this.hrsxdow;
      var semana = { d0: "", d1: "", d2: "", d3: "", d4: "", d5: "", d6: "" };
      var diasconfigurados = 0;

      for (let index = 0; index < arbolPrincipal.length; index++) {
        let arbol = arbolPrincipal[index];

        helper.reset(); // Importante para reiniciar el índice 2
        let factor = 60 / this.inter;
        let startH;
        let min;
        let endM;
        let startM;
        var status;
        var hr;
        var hrfin;
        var hrini;
        var auxH;
        let id = this.consIndex != -1 ? this.cons[this.consIndex].lugar_id : -1; // significaría nuevo

        var seleccionados = new Array();
        for (var i = 0; i < this.intervals; i++) {
          auxH = Math.floor(i / factor);
          startH = String(auxH).padStart(2, "0");
          min = (i % factor) * this.inter;
          endM = min + parseInt(this.inter) - 1;
          startM = String(min).padStart(2, "0");

          var h2 = auxH + (endM == 59 ? 1 : 0);
          var m2 = endM == 59 ? 0 : endM + 1;
          hr =
            startH +
            ":" +
            startM +
            "-" +
            String(h2).padStart(2, "0") +
            ":" +
            String(m2).padStart(2, "0");
          hrini = hr.split("-")[0];
          hrfin = hr.split("-")[1];

          let startEndArr = [
            Math.floor(i / factor) + min / 100,
            Math.floor(i / factor) + endM / 100,
          ];
          status = helper.statusSlot(id, startEndArr, arbol.ascOrder());

          if (status == 1)
            seleccionados.push({ indice: i, se: hr, s: hrini, e: hrfin });
        }

        if (seleccionados.length > 0) {
          var concat = "";
          var last = undefined;
          var eq = 0;
          for (var x = 0; x < seleccionados.length; x++) {
            var element = seleccionados[x];
            eq = x;
            var next =
              x + 1 < seleccionados.length ? seleccionados[x + 1] : undefined;
            var frag = "";
            last = undefined;
            while (
              next != undefined &&
              next.indice - 1 == seleccionados[eq].indice
            ) {
              last = next;
              eq = x;
              next = ++x < seleccionados.length ? seleccionados[x] : undefined;
            }

            if (last != undefined) {
              frag = element.s + "-" + last.e;
              if (next != undefined) x--;
            } else {
              frag = element.se;
            }
            concat += concat.length == 0 ? frag : "," + frag;
          }

          concat = concat.replace("24:00", "23:59");
          semana["d" + index] = concat;
          diasconfigurados++;
        }
      }
      //console.log(semana);
      if (diasconfigurados == 0) {
        this.message("Configura los horarios de atención", "warning");
        return null;
      }

      return semana;
    },
    validate: function() {
      this.$refs.form.validate();
    },
    borrar: function() {

      let idx = this.consIndex;
      if(idx === undefined || idx < 0){

        this.delDiag = false;
        this.borrando = false;
        return;
      }

      this.borrando = true;

      let id = this.cons[idx].lugar_id
      ConsultorioService.eliminar(id).then((data)=>{
        var ok = data > 0;
        this.message(ok? "El consultorio ha sido eliminado": "El consultorio no pudo ser eliminado, intenta más tarde.",
              ok ? "success" : "error");
        if (ok) {
            var auxArr = this.cons;
            auxArr.splice(idx, 1);
            this.nuevo();

        }
        this.obtHorarios();

      }).then(
          function() {
            this.delDiag = false;
            this.borrando = false;
          }.bind(this));
    },
    onPaisChange: function(v) {
      this.pais_tz = v;
    },
  },
  beforeMount() {
    this.obtPaises();
    this.obtHorarios();

  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
};
</script>
